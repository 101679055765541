@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;500;600;700&display=swap');

@font-face {
  font-family: 'Gazzetta';
  src: url('/public/font.woff2') format('woff2'),
    url('/public/font.woff') format('woff');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: white;
  background-color: black;
}

body {
  overscroll-behavior-y: none;
  font-family: 'Space Grotesk', sans-serif;
  color: black;
}

.brand {
  position: absolute;
  top: 40px;
  left: 40px;
}

a {
  color: white;
}

a {
  pointer-events: all;
  color: white;
  text-decoration: none;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 8%;
  pointer-events: none;
  height: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  transition: all 0.8s ease-in-out;
}

.wrapper {
  max-width: 1200px;
}

.background-container {
  background-image: radial-gradient(
    450em 16em at center 34%,
    #99e4bb 2%,
    #397686d0 30%,
    #3c7f9110 80%
  );
}

h1 {
  font-family: 'Gazzetta', sans-serif;
  font-size: clamp(3.5em, 11.5vw, 12em);
  font-weight: 100;
  line-height: 0.75em;
  transition: all 0.8s ease-in-out;
  text-shadow: 0px 0px 20px #000000a3;
}

h3 {
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.4em;
  margin-top: 50px;
  font-weight: 600;
  text-shadow: 0px 0px 20px #000000dd;
}

.input-email {
  background-color: #313131;
  border: 1px solid rgb(85, 85, 85);
  padding: 20px 35px;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bolder;
  margin-top: 40px;
  pointer-events: all;
  font-size: 20px;
  box-shadow: 0px 0px 0px 0px #2dff9d33;
  transition: all 0.6s ease-in-out;
  color: white;
  text-transform: uppercase;
}

.input-email:focus {
  outline: none;
}

.button-waitlist {
  background-color: #06eb80;
  border: none;
  padding: 20px 35px;
  font-family: 'Space Grotesk', sans-serif;
  font-weight: bolder;
  margin-top: 40px;
  margin-bottom: 16px;
  pointer-events: all;
  font-size: 20px;
  box-shadow: 0px 0px 0px 0px #2dff9d33;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.button-waitlist:focus {
  outline: 2px solid #ffffff;
}

.button-waitlist:hover {
  background-color: #9cffd1;
  box-shadow: 0px 0px 80px 20px #83ffc5fe;
  color: #136a00;
}

.consent {
  font-size: 0.85em;
  font-weight: 200;
  width: 500px;
  text-shadow: 0px 0px 10px #000000dd;
}

/* MOBILE */

@media only screen and (max-width: 600px) {
  .main-title {
    width: 85%;
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main-title p {
    width: 100%;
    font-size: 0.8em;
  }

  h1 {
    font-size: 18.5vw;
  }
  h3 {
    font-size: 3.6vw;
    margin-top: 30px;
  }
  .brand {
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    display: flex;
    padding: 26px;
    background-image: linear-gradient(
      to bottom,

      #000000 20%,
      #00000000 100%
    );
  }

  .brand img {
    width: 120px;
  }

  .button-waitlist {
    font-size: 1.2em;
  }

  .input-email {
    font-size: 0.8em;
    padding: 20px 20px;
    width: 140px;
  }

  .button-waitlist {
    font-size: 0.8em;
    padding: 20px 20px;
  }

  .consent {
    font-size: 0.85em;
    font-weight: 200;
    width: 90%;
  }
}
